<template>
  <div class="form p-3" v-if="tableInfo.length">
    <!-- Bordered Tables -->
    <table class="table table-bordered table-nowrap">
      <thead class="table-light">
        <tr>
          <template v-for="(head, index) in table_head" :key="index">
            <th scope="col">
              {{ head }}
            </th>
          </template>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(info, index) in tableInfo"
          :key="index"
          style="vertical-align: middle"
        >
          <th scope="row" v-if="highLevelSearch && showCheckbox">
            <div class="form-check d-flex align-items-center">
              <input
                class="form-check-input me-3 thCheckbox"
                type="checkbox"
                :id="info.id"
                @click="handlerChoose(info, index)"
              />
              <label class="form-check-label" for="activetableCheck01">{{
                info.number
              }}</label>
            </div>
          </th>
          <td scope="row" class="text-center" v-else>
            {{ info.number }}
          </td>
          <td>
            <a href="javascript:;" @click="handlerControl('view', info)">{{
              info.garment_id
            }}</a>
          </td>
          <td class="text-center">
            <a href="javascript:;" @click="handleImgPreview(info)">
              <img
                :src="getStyle(info)"
                style="width: 6rem; height: 4rem; object-fit: contain"
                @error="
                  (e) => {
                    e.target.src = defaultImage;
                  }
                "
              />
            </a>
          </td>
          <td>{{ info.name }}</td>
          <td>{{ info.type }}</td>
          <td>{{ fitting(info.fitting) }} / {{ info.sizing }}</td>
          <td v-if="false">
            <ImagePopover :info="info" :type="0"></ImagePopover>
          </td>
          <td class="text-center">
            <a href="javascript:;" @click="handlerControl('view', info, 1, 0)">
              <img
                src="@/assets/1.png"
                style="width: 6rem; height: 4rem; object-fit: contain"
                :alt="$t('base.no_pictures')"
              />
            </a>
          </td>
          <td class="text-center">
            <a href="javascript:;" @click="handlerControl('view', info, 1, 0)">
              <img
                src="@/assets/2.png"
                style="width: 6rem; height: 4rem; object-fit: contain"
                :alt="$t('base.no_pictures')"
              />
            </a>
          </td>
          <td class="text-center">
            <a href="javascript:;" @click="handlerControl('view', info, 1, 1)">
              <img
                src="@/assets/3.png"
                style="width: 6rem; height: 4rem; object-fit: contain"
                :alt="$t('base.no_pictures')"
              />
            </a>
          </td>
          <td>{{ info.brand_name }}</td>
          <td>{{ info.supplier_name }}</td>
          <td>{{ info.price }}</td>
          <td>{{ info.inventory }}</td>
          <td v-if="showOperation()">
            <div class="dropdown">
              <a
                href="#"
                role="button"
                id="dropdownMenuLink"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                ><i class="ri-more-2-fill"></i
              ></a>
              <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                <li v-permit="'garment.show'">
                  <div
                    class="dropdown-item cursor"
                    @click="handlerControl('view', info)"
                  >
                    {{ $t("base.to_view") }}
                  </div>
                </li>
                <li v-permit="'garment.modify'" v-if="info['can_edit']">
                  <div
                    class="dropdown-item cursor"
                    @click="handlerControl('edit', info)"
                  >
                    {{ $t("base.edit") }}
                  </div>
                </li>
                <li v-permit="'garment.delete'" v-if="info['can_edit']">
                  <div
                    class="dropdown-item cursor"
                    @click="handlerControl('delete', info)"
                  >
                    {{ $t("base.delete") }}
                  </div>
                </li>
              </ul>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div v-else>
    <Empty v-if="!tableLoading"></Empty>
    <Loading v-else></Loading>
  </div>
  <message-alert
    :alertInfo="alertInfo"
    v-if="formValidataAlert"
  ></message-alert>
  <PreviewImg
    v-model:showPreviewImg="showPreviewImg"
    :src="src"
    v-if="showPreviewImg"
  ></PreviewImg>
</template>

<script>
import PreviewImg from "@/components/common/PreviewImg";
import defaultImage from "@/assets/image.png";
import { deleteGarmentDetail } from "@/service/index";
import Swal from "sweetalert2";
import _ from "lodash";
import ImagePopover from "@/views/components/ImagePopover";
import { fit_options } from "./datalist";
export default {
  components: {
    PreviewImg,
    ImagePopover,
  },
  props: {
    tableInfo: {
      type: Array,
      default: () => [],
    },
    tableLoading: {
      type: Boolean,
      default: false,
    },
    highLevelSearch: {
      type: Boolean,
      default: false,
    },
    showCheckbox: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["initGarment", "handlerChooseChart", "update:handlerClickTable"],
  data() {
    return {
      defaultImage,
      alertInfo: {
        variant: "danger",
        content: "",
        icon: "ri-error-warning-line",
      },
      formValidataAlert: false,
      showPreviewImg: false,
      src: "",
      currentChooseIndex: null,
    };
  },
  computed: {
    table_head() {
      let head = [];
      if (this.highLevelSearch && this.showCheckbox) {
        head = [
          "",
          this.$t("attribute.id"),
          this.$t("attribute.style"),
          this.$t("attribute.name"),
          this.$t("attribute.type"),
          this.$t("attribute.fitting") + "/" + this.$t("attribute.size"),
          this.$t("radar_chart.feel"),
          this.$t("radar_chart.skin"),
          this.$t("radar_chart.heat"),
          this.$t("attribute.brand"),
          this.$t("attribute.supplier"),
          this.$t("attribute.cost") +
            " (" +
            this.$t("attribute.price_unit") +
            ")",
          this.$t("attribute.stock") +
            " (" +
            this.$t("attribute.stock_unit") +
            ")",
          this.$t("base.operation"),
        ];
      } else {
        head = [
          this.$t("base.No"),
          this.$t("attribute.id"),
          this.$t("attribute.style"),
          this.$t("attribute.name"),
          this.$t("attribute.type"),
          this.$t("attribute.fitting") + "/" + this.$t("attribute.size"),
          this.$t("radar_chart.feel"),
          this.$t("radar_chart.skin"),
          this.$t("radar_chart.heat"),
          this.$t("attribute.brand"),
          this.$t("attribute.supplier"),
          this.$t("attribute.cost") +
            " (" +
            this.$t("attribute.price_unit") +
            ")",
          this.$t("attribute.stock") +
            " (" +
            this.$t("attribute.stock_unit") +
            ")",
          this.$t("base.operation"),
        ];
      }
      return head;
    },
  },
  created() {
    if (!this.showOperation()) {
      this.table_head.pop();
    }
  },
  methods: {
    handlerChoose(info, index) {
      this.currentChooseIndex = index;
      this.filterCheckBox();
      this.$emit("handlerChooseChart", info, true);
    },
    filterCheckBox() {
      let dom = document.querySelectorAll(".thCheckbox");
      if (dom) {
        dom.forEach((d, index) => {
          if (index == this.currentChooseIndex) {
            d.checked = true;
          } else {
            d.checked = false;
          }
        });
      }
    },
    fitting(fitting) {
      let i = 0;
      for (i = 0; i < fit_options.length; i++) {
        const item = fit_options[i];
        if (item.key == fitting) {
          return item.value;
        }
      }
      return fitting;
    },
    handlerControl(type, info, tab, d_tab) {
      if (type == "delete") {
        Swal.fire({
          title: this.$t("base.delete_alert_title"),
          text: this.$t("base.delete_alert_info"),
          icon: "warning",
          showCancelButton: true,
          cancelButtonColor: "#f46a6a",
          confirmButtonColor: "#34c38f",
          confirmButtonText: this.$t("base.done"),
          cancelButtonText: this.$t("base.cancel"),
        }).then((result) => {
          if (result.isConfirmed) {
            deleteGarmentDetail(info.id)
              .then((res) => {
                if (res) {
                  Swal.fire(
                    this.$t("base.success"),
                    this.$t("base.delete_alert_completed"),
                    "success"
                  );
                }
              })
              .then(() => {
                this.$emit("initGarment");
              })
              .catch((e) => {
                this.formValidataAlert = true;
                this.alertInfo = {
                  variant: "danger",
                  content: e.message,
                  icon: "ri-alert-line",
                };
                setTimeout(() => {
                  this.formValidataAlert = false;
                }, 4000);
              });
          }
        });
      } else {
        if (tab) {
          let url = this.$router.resolve({
            path: "/garment_detail",
            query: {
              id: info.id,
              type: type,
              current_tab: tab,
              detail_tab: d_tab,
            },
          });
          window.open(url.href, "_blank");
          // this.$router.push(
          //   `/garment_detail/?id=${info.id}&type=${type}&current_tab=${tab}&detail_tab=${d_tab}`
          // );
        } else {
          let url = this.$router.resolve({
            path: "/garment_detail",
            query: {
              id: info.id,
              type: type,
            },
          });
          window.open(url.href, "_blank");
          // this.$router.push(`/garment_detail/?id=${info.id}&type=${type}`);
        }
      }
    },
    getStyle(info) {
      let style = _.get(info, "style", []);
      if (style == "[]" || !style.length) {
        return "";
      }
      return _.get(style, "0.url", "");
    },
    handleImgPreview(item) {
      this.src = this.getStyle(item);
      if (this.src == "") {
        return;
      }
      this.showPreviewImg = true;
    },
    showOperation() {
      const params = {
        list: ["garment.show", "garment.modify", "garment.delete"],
        type: "some",
      };
      return this.$permit(params);
    },
  },
};
</script>

<style scoped lang="scss">
</style>
