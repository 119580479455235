const name_options = [{
		name: "shirt 01",
	},
	{
		name: "shirt 02",
	},
	{
		name: "shirt 03",
	},
	{
		name: "shirt 04",
	},
]
const type_options = [{
		name: "Business shirt",
	},
	{
		name: "portswear",
	},
	{
		name: "portswear 03",
	},
	{
		name: "portswear 04",
	},
]

import i18n from "@/i18n"

// const fit_options = {
//     'just fit': i18n.global.t('attribute.just_fit'),
//     'tight fit': i18n.global.t('attribute.tight_fit'),
//     'loose fit': i18n.global.t('attribute.loose_fit'),
// }
const fit_options = [{
	key: 'just fit',
	value: i18n.global.t('attribute.just_fit'),
}, {
	key: 'tight fit',
	value: i18n.global.t('attribute.tight_fit'),
}, {
	key: 'loose fit',
	value: i18n.global.t('attribute.loose_fit'),
}]
// const fit_options = [
// {
//     name: "loose fit / 3D",
// },
// {
//     name: "loose fit / -",
// },
// {
//     name: "just fit / 3D",
// }
// ]
const brand_options = [{
		name: "Bivolino",
	},
	{
		name: "Bivolino 2",
	},
	{
		name: "Bivolino 3",
	}
]
const supplier_options = [{
		name: "Bivolino",
	},
	{
		name: "Bivolino 2",
	},
	{
		name: "Bivolino 3",
	}
]
const tableInfo = [{
		id: "100011",
		name: "Jean",
		shape: "",
		type: "100011",
		size: "nip.nie@goldendata.cloud",
		feel: "good",
		skin_comfort: "YSR",
		heat_humidity: "Management",
		brand: "Bivolino",
		supplier: "Bivolino",
		cost: "80",
		stock: '66'
	},
	{
		id: "100012",
		name: "Jean",
		shape: "https://fs.goldendata.cloud/thrillerbark/20220801/Alabasta云图设计-1920-01.png",
		type: "100012",
		size: "nip.nie@goldendata.cloud",
		feel: "good",
		skin_comfort: "YSR",
		heat_humidity: "Production Leader",
		brand: "Bivolino",
		supplier: "Bivolino",
		cost: "80",
		stock: '66'
	},
	{
		id: "100013",
		name: "Jean",
		shape: "https://fs.goldendata.cloud/thrillerbark/20220801/Alabasta云图设计-1920-01.png",
		type: "100013",
		size: "nip.nie@goldendata.cloud",
		feel: "good",
		skin_comfort: "YSR",
		heat_humidity: "Depot Operator",
		brand: "Bivolino",
		supplier: "Bivolino",
		cost: "80",
		stock: '66'
	},
	{
		id: "100014",
		name: "Jean",
		shape: "https://fs.goldendata.cloud/thrillerbark/20220801/Alabasta云图设计-1920-01.png",
		type: "100013",
		size: "nip.nie@goldendata.cloud",
		feel: "good",
		skin_comfort: "Production",
		heat_humidity: "Plant Operator",
		brand: "Bivolino",
		supplier: "Bivolino",
		cost: "80",
		stock: '66'
	},
	{
		id: "100015",
		name: "Jean",
		shape: "https://fs.goldendata.cloud/thrillerbark/20220801/Alabasta云图设计-1920-01.png",
		type: "100015",
		size: "nip.nie@goldendata.cloud",
		feel: "good",
		skin_comfort: "Quality Inspection",
		heat_humidity: "Lab Operator",
		brand: "Bivolino",
		supplier: "Bivolino",
		cost: "80",
		stock: '66'
	},
]

/* 品目编码 */
const code = [
	"6101 针织或钩编的男式大衣、短大衣、斗蓬、短斗蓬、带风帽的防寒短上衣(包括滑雪短上衣)、防风衣、防风短上衣及类似品，但品目 6103 的货品除外",
	'6102 针织或钩编的女式大衣、短大衣、斗蓬、短斗蓬、带风帽的防寒短上衣(包括滑雪短上衣)、防风衣、防风短上衣及类似品，但品目 6104 的货品除外',
	'6103 针织或钩编的男式西服套装、便服套装、上衣、长裤、护胸背带工装裤、马裤及短裤 （游泳裤除外）',
	'6104 针织或钩编的女式西服套装、便服套装、上衣、连衣裙、裙子、裙裤、长裤、护胸背带 工装裤、马裤及短裤（游泳服除外）',
	'6105 针织或钩编的男衬衫',
	'6106 针织或钩编的女衬衫',
	'6107 针织或钩编的男式内裤、三角裤、长睡衣、睡衣裤、浴衣、晨衣及类似品',
	'6108 针织或钩编的女式长衬裙、衬裙、三角裤、短衬裤、睡衣、睡衣裤、浴衣、晨衣及类似品',
	'6109 针织或钩编的Ｔ恤衫、汗衫及其他背心',
	'6110 针织或钩编的套头衫、开襟衫、背心及类似品',
	'6111 针织或钩编的婴儿服装及衣着附件',
	'6112 针织或钩编的运动服、滑雪服及游泳服',
	'6113 用品目 5903、5906 或 5907 的针织物或钩编织物制成的服装',
	'6114 针织或钩编的其他服装',
	'6201 男式大衣、短大衣、斗蓬、短斗蓬、带风帽的防寒短上衣（包括滑雪短上衣）、防风 衣、防风短上衣及类似品，但品目 6203 的货品除外',
	'6202 女式大衣、短大衣、斗蓬、短斗蓬、带风帽的防寒短上衣（包括滑雪短上衣）、防风 衣、防风短上衣及类似品，但品目 6204 的货品除外',
	'6203 男式西服套装、便服套装、上衣、长裤、护胸背带工装裤、马裤及短裤（游泳裤外）',
	'6204 女式西服套装、便服套装、上衣、连衣裙、裙子、裙裤、长裤、护胸背带工装裤、马裤及短裤（游泳服除外）',
	'6205 男衬衫',
	'6206 女衬衫',
	'6207 男式背心及其他内衣、内裤、三角裤、长睡衣、睡衣裤、浴衣、晨衣及类似品',
	'6208 女式背心及其他内衣、长衬裙、衬裙、三角裤、短衬裤、睡衣、睡衣裤、浴衣、晨衣及类似品',
	'6209 婴儿服装及衣着附件',
	'6210 用品目 5602、5603、5903、5906 或 5907 的织物制成的服装',
	'6211 运动服、滑雪服及游泳服；其他服装',
	'6212 胸罩、束腰带、紧身胸衣、吊裤带、吊袜带、束袜带和类似品及其零件，不论是否针织 或钩编的',
	'6309 旧衣物'
]
export {
	name_options,
	type_options,
	fit_options,
	brand_options,
	supplier_options,
	tableInfo,
	code
}
