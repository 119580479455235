<template>
	<a :id="target" href="javascript:;" @click="handlerControl()" @mouseover="mouseOver" @mouseleave="mouseLeave">
		<img :src="imgSrc" style="width: 6rem; height: 3rem; object-fit: contain;" />
	</a>
	<b-popover :target="target" v-model:show="show" triggers="">
		<template #title>
			{{ title }}
		</template>
		<div class="d-flex justify-content-between mb-3" v-for="item in items" :key="item">
			<div class="item">{{ item.key }}</div>
			<div>{{ item.value }}</div>
		</div>
	</b-popover>
</template>

<script>
import _ from 'lodash';
import feelImg from '@/assets/img/hand_feel.png';
import skinImg from '@/assets/img/skin_comfort.png';
import heatImg from '@/assets/img/thermal_comfort.png';
export default {
	props: {
		info: {
			type: Object,
			default() {
				return {};
			}
		},
		type: {
			type: Number,
			default: 0
		}
	},
	data() {
		return {
			feelImg,
			skinImg,
			heatImg,
			triggers: 'hover focus',
			show: false
		};
	},
	methods: {
		aaa() {
			this.show = true;
		},
		mouseOver() {
			this.show = true
		},
		mouseLeave() {
			this.show = false
		},
		number_fixed(data) {
			if (data == '') {
				return data;
			}
			return parseFloat(data.toFixed(2));
		},
		handlerControl() {
			this.show = false;
			if (this.type == 2) {
				this.$router.push(`/garment_detail/?id=${this.info.id}&type=view&current_tab=3`);
			} else {
				this.$router.push(`/garment_detail/?id=${this.info.id}&type=view&current_tab=2`);
			}
		}
	},
	computed: {
		imgSrc() {
			if (this.type == 1) {
				return this.skinImg;
			}
			if (this.type == 2) {
				return this.heatImg;
			}
			return this.feelImg;
		},
		target() {
			if (this.type == 1) {
				return `${this.info.id}` + 'skin';
			}
			if (this.type == 2) {
				return `${this.info.id}` + 'heat';
			}
			return `${this.info.id}` + 'feel';
		},
		title() {
			if (this.type == 1) {
				return this.$t('radar_chart.skin');
			}
			if (this.type == 2) {
				return this.$t('radar_chart.heat');
			}
			return this.$t('radar_chart.feel');
		},
		items() {
			let tmps = [];
			if (this.type == 1) {
				tmps.push({
					key: this.$t('radar_chart.prickle'),
					value: this.number_fixed(_.get(this.info, 'skin_comfort.skin_smooth', 0))
				});
				tmps.push({
					key: this.$t('radar_chart.softness'),
					value: this.number_fixed(_.get(this.info, 'skin_comfort.skin_stiff', 0))
				});
				tmps.push({
					key: this.$t('radar_chart.warmth'),
					value: this.number_fixed(_.get(this.info, 'skin_comfort.skin_cool', 0))
				});
				tmps.push({
					key: this.$t('radar_chart.total'),
					value: this.number_fixed(_.get(this.info, 'skin_comfort.skin_total', 0))
				});
			} else if (this.type == 2) {
				tmps.push({
					key: this.$t('radar_chart.comfort_value'),
					value: this.number_fixed(_.get(this.info, 'score_dat.ds_normalized', 0))
				});
				tmps.push({
					key: this.$t('radar_chart.dampness_sensation'),
					value: this.number_fixed(_.get(this.info, 'score_dat.ts_normalized', 0))
				});
				tmps.push({
					key: this.$t('radar_chart.thermal_sensation'),
					value: this.number_fixed(_.get(this.info, 'score_dat.cv_normalized', 0))
				});
				tmps.push({
					key: this.$t('radar_chart.total'),
					value: this.number_fixed(_.get(this.info, 'score_dat.t_normalized', 0))
				});
			} else {
				tmps.push({
					key: this.$t('radar_chart.roughness'),
					value: this.number_fixed(_.get(this.info, 'hand_feel.hand_smooth', 0))
				});
				tmps.push({
					key: this.$t('radar_chart.softness'),
					value: this.number_fixed(_.get(this.info, 'hand_feel.hand_stiff', 0))
				});
				tmps.push({
					key: this.$t('radar_chart.warmth'),
					value: this.number_fixed(_.get(this.info, 'hand_feel.hand_cool', 0))
				});
				tmps.push({
					key: this.$t('radar_chart.total'),
					value: this.number_fixed(_.get(this.info, 'hand_feel.hand_total', 0))
				});
			}
			return tmps;
		}
	}
};
</script>

<style>
.item {
	margin-right: 20px;
}
</style>
