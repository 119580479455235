<template>
  <Layout>
    <PageHeader :title="PageHeaderT" :items="PageHeaderItems" />
    <div class="bg-white" style="overflow-x: auto;">
      <div class="card p-4" style="max-height: 55%;box-shadow: none;">
        <Search
          v-model:searchInfo="params"
          v-model:highLevelSearch="highLevelSearch"
          v-model:showCheckbox="showCheckbox"
          :handlerClickTable="handlerClickTable"
          :search_list="search_list"
          :chooseTableInfo="chooseTableInfo"
          @initGarment="initGarment"
        ></Search>
      </div>
      <div class="card mb-0" style="box-shadow: none;">
        <div>
          <Form
            :tableInfo="tableData"
            :table-loading="tableDataLoading"
            :highLevelSearch="highLevelSearch"
            :showCheckbox="showCheckbox"
            @handlerChooseChart="handlerChooseChart"
            @initGarment="initGarment"
          ></Form>
        </div>
        <Pagination
          v-if="pagination.total"
          v-model:page="pagination.currentPage"
          v-model:perPage="pagination.pageSize"
          :pages="pagination.pageOption"
          :prePageOption="pagination.pageSizeOption"
          :tab_info_length="pagination.total"
          class="me-5 my-3"
        >
        </Pagination>
      </div>
    </div>
  </Layout>
</template>

<script>
import Search from "./Search";
import Form from "./Form";
import { getGarmentList } from "@/service";
import { fit_options } from "./datalist";
import { tableMixin } from "@/mixins/Table1.js";
import _ from "loadsh";
export default {
  components: {
    Search,
    Form,
  },
  mixins: [tableMixin],
  data() {
    return {
      PageHeaderT: this.$t("garment.title"),
      PageHeaderItems: [
        {
          text: this.$t("garment.title"),
          active: true,
          href: "/garment",
        },
      ],
      search_list: {},
      showCheckbox: false,
      highLevelSearch: false,
      handlerClickTable: false,
      chooseTableInfo: null,
    };
  },
  methods: {
    handlerChooseChart(info, status) {
      let chartInfo = _.cloneDeep(info);
      this.chooseTableInfo = _.merge(
        {},
        chartInfo.skin_comfort,
        chartInfo.hand_feel,
        chartInfo.score_dat
      );
      this.handlerClickTable = status;
    },
    initGarment() {
      this.getFirstPage();
    },
    requestList(params) {
      getGarmentList(params)
        .then((res) => {
          if (res && res.payload && res.payload.data) {
            this.updateData(res.payload);
            let searchList = res.payload.search_list;
            searchList.fitting = this.formatFitting(searchList.fitting);
            this.search_list = searchList;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    formatFitting(fitting) {
      let list = [];
      for (var item of fitting) {
        if (item && item.length) {
          const value = this.fitting(item);
          list.push({
            key: item,
            value: value,
          });
        }
      }
      return list;
    },
    fitting(fitting) {
      let i = 0;
      for (i = 0; i < fit_options.length; i++) {
        const item = fit_options[i];
        if (item.key == fitting) {
          return item.value;
        }
      }
      return fitting;
    },
  },
};
</script>

<style lang="scss">
.table-responsive {
  min-height: 60vh;
  max-height: 60vh;
}

.mask_model {
  width: 100vw;
  height: 100vh;
  background-color: rgba(47, 79, 79, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
}
</style>
