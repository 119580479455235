<template>
  <!-- 按钮 -->
  <div class="d-flex justify-content-between mb-3">
    <button
      v-permit="'garment.add'"
      type="button"
      class="btn btn-success me-3"
      @click="addClick()"
    >
      <i class="ri-add-fill me-1 align-bottom"></i>
      {{ $t("garment.add") }}
    </button>
    <b-button
      variant="primary"
      v-b-toggle.searchCollapse1.searchCollapse2
      @click="collapseClick"
    >
      <i class="ri-equalizer-fill me-1 align-bottom"></i>
      {{ $t("base.advance_search") }}
    </b-button>
  </div>

  <!-- 关键字搜索 -->
  <div>
    <div class="collapse search-collapse show" id="searchCollapse1">
      <div class="d-flex justify-content-between">
        <div class="flex-1 me-3">
          <div class="search-box" style="margin: auto!important;width: auto!important;">
            <input
              type="text"
              class="form-control"
              :placeholder="$t('garment.keyword_placeholder')"
              v-model="keyword"
            />
            <i class="ri-search-line search-icon"></i>
          </div>
        </div>
        <div class="">
          <button
            type="button"
            class="btn btn-primary me-3"
            @click="searchClick()"
          >
            <i class="ri-search-line me-1 align-bottom"></i>
            {{ $t("base.search") }}
          </button>
          <button type="button" class="btn btn-info" @click="resetClick()">
            <i class="ri-reply-line me-1 align-bottom"></i>
            {{ $t("base.reset") }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- 高级搜索 -->
  <div class="collapse search-collapse" id="searchCollapse2">
    <b-row>
      <b-col>
        <b-row>
          <b-col :cols="cols">
            <label for="weight-field" class="form-label">
              <span class="text-danger">*</span>
              <span>{{ $t("attribute.code") }}</span>
            </label>
            <Multiselect
              id="garment"
              class="form-control"
              v-model="select_code"
              label="title"
              value-prop="code"
              :close-on-select="true"
              :searchable="true"
              :create-option="false"
              :options="search_list.code"
              @input="handlerSelect('code')"
              :class="{
                'is-invalid':
                  highLevelSearch && submitted && v$.select_code.$error,
              }"
              required
            />
            <div
              v-for="(item, index) in v$.select_code.$errors"
              :key="index"
              class="invalid-feedback position-absolute"
            >
              <span v-if="item.$message">{{ item.$message }}</span>
            </div>
          </b-col>
          <b-col :cols="cols">
            <label class="form-label">{{ $t("attribute.size") }}</label>
            <Multiselect
              id="garment"
              class="form-control"
              v-model="search_size"
              :mode="select_mode_fit"
              label="value"
              value-prop="key"
              :close-on-select="true"
              :searchable="true"
              :create-option="false"
              :options="search_list.fitting"
              @input="handlerSelect('fit')"
            />
          </b-col>
          <b-col :cols="cols">
            <label class="form-label">{{
              $t("attribute.cost") + " (" + $t("attribute.price_unit") + ")"
            }}</label>
            <InputNumberRange
              ref="cost"
              @numberChange="costChange"
            ></InputNumberRange>
          </b-col>
          <b-col :cols="cols">
            <label class="form-label">{{
              $t("attribute.stock") + " (" + $t("attribute.stock_unit") + ")"
            }}</label>
            <InputNumberRange
              ref="stock"
              @numberChange="stockChange"
            ></InputNumberRange>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="auto">
        <div
          class="row"
          style="position: relative; top: 100%; transform: translateY(-100%)"
        >
          <div>
            <div class="col-auto">
              <button
                type="button"
                class="btn btn-primary me-3"
                @click="searchClick()"
              >
                <i class="ri-search-line me-1 align-bottom"></i>
                {{ $t("base.search") }}
              </button>
              <button type="button" class="btn btn-info" @click="resetClick()">
                <i class="ri-reply-line me-1 align-bottom"></i>
                {{ $t("base.reset") }}
              </button>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row v-if="!searchVisiable"
      ><SearchChart
        ref="searchChart"
        v-model:chartData="chartData"
        v-model:resetChart="resetChart"
        :chooseTableInfo="chooseTableInfo"
        :handlerClickTable="handlerClickTable"
        @updateFunction="updateFunction"
      ></SearchChart
    ></b-row>
  </div>
</template>

<script>
import InputNumberRange from "@/views/components/InputNumberRange";
import SearchChart from "@/views/components/SearchChart";
import _ from "lodash";
import { required, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import i18n from "@/i18n";
export default {
  components: {
    InputNumberRange,
    SearchChart,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  validations: {
    select_code: {
      required: helpers.withMessage(i18n.global.t("base.hint_code"), required),
    },
  },
  data() {
    return {
      submitted: false,
      /* id */
      search_id: "",
      /* --- name --- */
      search_name: [],
      select_mode_name: "tags",
      /* --- code --- */
      select_code: "",
      select_mode_code: "tags",
      /* --- type --- */
      search_type: [],
      select_mode_type: "tags",
      /* --- fit --- */
      search_size: [],
      select_mode_fit: "tags",
      /* --- brand --- */
      search_brand: [],
      select_mode_brand: "tags",
      /* --- supplier --- */
      search_supplier: [],
      select_mode_supplier: "tags",
      /* --- cost --- */
      cost: {
        start: "",
        end: "",
      },
      /* --- stock --- */
      stock: {
        start: "",
        end: "",
      },
      keyword: "",
      searchVisiable: true,
      cols: 3,
      chartData: {},
      resetChart: false,
    };
  },
  emits: [
    "update:searchInfo",
    "update:highLevelSearch",
    "update:showCheckbox",
    "update:page",
    "initGarment",
  ],
  props: {
    searchInfo: {
      type: Object,
      default: () => {},
    },
    search_list: {
      type: Object,
      default: () => {},
    },
    handlerClickTable: {
      type: Boolean,
      default: false,
    },
    chooseTableInfo: {
      type: Object,
      default: () => {},
    },
    highLevelSearch: {
      type: Boolean,
      default: false,
    },
    showCheckbox: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {},
  methods: {
    costChange(data) {
      this.cost.start = data[0];
      this.cost.end = data[1];
    },
    stockChange(data) {
      this.stock.start = data[0];
      this.stock.end = data[1];
    },
    collapseClick() {
      this.searchVisiable = !this.searchVisiable;
      this.resetClick();
      this.$emit("update:highLevelSearch", !this.searchVisiable);
    },
    /* 切换多选框展示样式 */
    handlerSelect(type) {
      let selectType = `select_mode_${type}`;
      let selectData = `search_${type}`;
      if (this[selectData] && this[selectData].length >= 3) {
        this[selectType] = "multiple";
      } else {
        this[selectType] = "tags";
      }
    },
    /* 新增 */
    addClick() {
      this.$router.push(`/garment_detail/?type=add`);
    },
    updateParams() {
      let info = _.cloneDeep(this.chartData);
      if (this.select_code && this.select_code.length > 0) {
        info["code"] = this.select_code;
      }
      if (this.keyword && this.keyword.length > 0) {
        info["keyword"] = this.keyword;
      }
      if (this.search_size.length) {
        // info['size'] = this.search_size
        const tmp = JSON.stringify(this.search_size);
        info["size"] = tmp;
      }
      if (this.cost.start && this.cost.start.length > 0) {
        info["price_min"] = this.cost.start;
      }
      if (this.cost.end && this.cost.end.length > 0) {
        info["price_max"] = this.cost.end;
      }
      if (this.stock.start && this.stock.end.length > 0) {
        info["inventory_min"] = this.stock.start;
      }
      if (this.stock.end && this.stock.end.length > 0) {
        info["inventory_max"] = this.stock.end;
      }
      this.$emit("update:searchInfo", info);
    },
    searchClick() {
      this.submitted = true;
      this.v$.$touch();

      if (this.v$.$invalid && this.highLevelSearch && !this.select_code) {
        return;
      } else {
        this.resetChart = false;
        // 判断是否有拖动雷达图，切换展示表单选择框
        let chartVal = Object.values(this.chartData);
        let status = chartVal.filter((val) => {
          return val;
        });
        if (status.length) {
          this.$emit("update:showCheckbox", true);
        } else {
          this.$emit("update:showCheckbox", false);
        }
        this.updateParams();
      }
    },
    resetClick() {
      this.submitted = false;
      this.select_code = "";
      this.search_id = "";
      this.search_name = [];
      this.search_type = [];
      this.search_size = [];
      this.search_brand = [];
      this.search_supplier = [];
      this.cost = {
        start: "",
        end: "",
      };
      this.stock = {
        start: "",
        end: "",
      };
      this.keyword = "";
      if (this.$refs.cost) {
        this.$refs.cost.reset();
      }
      if (this.$refs.stock) {
        this.$refs.stock.reset();
      }
      if (this.$refs.searchChart) {
        this.$refs.searchChart.reset();
      }
      this.updateFunction();
    },
    updateFunction() {
      this.$emit("update:showCheckbox", false);
      this.updateParams();
    },
  },
};
</script>

<style scoped lang="scss"></style>

<style>
.form-label {
  margin-top: 10px;
}
</style>
